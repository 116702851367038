<div style="max-width: 700px; margin: 0 auto;" (click)="tapContent(); $event.stopPropagation();">      
  <ng-container *ngIf="conversationService.isStatementLoaded$ | async as loaded; else checkCompletionText">
    <!-- Chat Title / Progress Bar -->
    <ng-container *ngIf="conversationService.conversation as conversation">
      <ng-container *ngIf="conversationService.chatProgress$ | async as progress">
        <div class="conversation-title">
          {{ (conversation.title || conversation.name) + (progress.showProgress ? " - " + progress.description : "" ) }}
        </div>
        <div style="height: 30px; margin: 0;">
          <ion-grid *ngIf="conversation.statements.length > 1" class="progress-bar-grid">
            <ion-row>
              <ion-col size="1" style="text-align: right">
                <ion-icon *ngIf="includeNav"               
                  name="caret-back-outline" 
                  style="font-size: 28px; color: var(--chea-raspberry);"
                  (click)="conversationService.handleBack(); $event.stopPropagation();"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <ion-progress-bar [value]="progress.value"></ion-progress-bar>
              </ion-col>
              <ion-col size="1" style="text-align: left">
                <ion-icon *ngIf="includeNav"
                  name="caret-forward-outline" 
                  style="font-size: 28px; color: var(--chea-raspberry);"
                  (click)="continueForward(); $event.stopPropagation();"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ng-container>
    
      <!-- No Statement Error -->
      <div *ngIf="!conversation || conversation.statements.length===0" style="margin-top:100px;text-align:center">
        <h2 style="color: var(--chea-raspberry)" [innerHtml]="contentService.getGlobal('nostatement.message')"></h2>
      </div>
    </ng-container>
    
    <!-- Statement Content Node -->
    <div id="contentNode" *ngIf="currstmt" class="statement-div">
      <!-- Nothing defined -->
      <div style="margin-top:100px;text-align:center" *ngIf="!currstmt.title && !currstmt.textBlock && !currstmt.attributeSpec">
        <h2 style="color:red">Missing definition for statement {{currstmt.name}}</h2>
      </div>
      <!-- Error -->
      <div *ngIf="conversationService.currerror$ | async as currerror" class="stmterror">
          <p> 
            {{ currerror.error + " in: " }}
            <b>{{ currerror?.source }}</b>
          </p>
      </div>
      <!-- Title -->
      <div *ngIf="currstmt.title">
        <h2>{{currstmt.title}}</h2>
      </div>
      
      <!-- Video Block -->
      <ng-container *ngIf="conversationService.videoURL && currstmt.isVideoFirst">
        <div style="padding:75% 0 0 0;position:relative;">
          <iframe [src]="conversationService.videoURL" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </ng-container>

      <!-- Text Block -->
      <!-- only display if there's something to render -->
      <ng-container *ngIf="conversationService.statementHasBehavior('WaitForPromptResponse'); else displayTextBlock">
        <ng-container *ngIf="conversationService.chatPromptStatus$ | async as status">
          <ng-container *ngIf="status.waiting; else displayTextBlock">
            <cheaseed-labelled-spinner class="prompt-spinner"
              [title]="status.spinnerTitle?.title" 
              [subtitle]="status.spinnerTitle?.subtitle"/>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #displayTextBlock>
        <div *ngIf="currstmt.textBlock && !!currstmt.renderBlock && !currstmt.questionFirst && !currstmt.isTakeawayStatement">
          <ng-container *ngIf="conversationService.currentTextRendering$ | async as renderBlock">
            <div class="statement-text" [style.background-color]="currstmt.customTextBackgroundColor" [innerHTML]="renderBlock"></div>
          </ng-container>
        </div>

        <!-- Tile Block -->
        <div *ngIf="currstmt.isTakeawayStatement" class="takeawayStatement">
          <img [src]="currstmt.takeawayTileUrl" (load)="onTakeawayImageLoad()">
          <!-- <ng-container *ngIf="currstmt.imageLoaded"> -->
            <div style="display:flex; flex-direction: row;">
              <ng-container *ngIf="conversationService.isPinned$ | async as p; else unpinned">
                <div style="margin-right: 24px;">
                  <button style="color: lightgray" mat-fab (click)="unpinChat(); $event.stopPropagation()">
                    <mat-icon>push_pin_outline</mat-icon>
                  </button>
                  <div class="icon-label">Unpin</div>
                </div>
              </ng-container>
              <ng-template #unpinned>
                <div *ngIf="launchedInApp" style="margin-right: 24px;">
                  <button mat-fab (click)="pinChat(); $event.stopPropagation()">
                    <mat-icon>push_pin</mat-icon>
                  </button>
                  <div class="icon-label">Pin</div>
                </div>
              </ng-template>
              <div *ngIf="launchedInApp" style="margin-right: 24px;">
                <button mat-fab (click)="shareTile(); $event.stopPropagation()">
                  <mat-icon>ios_share</mat-icon>
                </button>
                <div class="icon-label">Share</div>
              </div>
            </div>
            <div class="feedback-div">
              <cheaseed-chat-feedback (feedbackChanged)="feedbackChanged($event)"></cheaseed-chat-feedback>
            </div>    
          <!-- </ng-container> -->
        </div>

        <!-- <div *ngIf="statementHasBehavior('SwipeLeftMessage')" 
            class="{{ (conversation.type === 'Settings' ? 'swipeLeftMessageBottom' : 'swipeLeftMessage') + ' fading' }}">
          Tap Anywhere To Continue
        </div> -->

        <!-- Attribute Spec -->
        <!-- Question -->
        <div *ngIf="conversationService.currentAttribute() as attribute">
          <div *ngIf="attribute.question"
              class="statement-text"              
              [innerHTML]="attribute.formattedQuestion">
          </div>
          <!-- text -->
          <div *ngIf="attribute.inputType==='TEXT'">
            <ion-input 
              style="margin-top: 24px;"
              placeholder="{{attribute.placeholder}}"
              [clearInput]="!!attribute.clearable"
              [(ngModel)]="attribute.value" 
              (ionChange)="attribute.changed=true">
            </ion-input>
          </div>
          <!-- text area -->
          <div *ngIf="attribute.inputType==='TEXTAREA'">
            <div *ngIf="attribute.buttonParams?.buttons as buttons" style="text-align: center">
              <ng-container *ngFor="let val of buttons">
                <button mat-raised-button (click)="attribute.value = val; attribute.changed = true;" style="margin: 4px; height: 24px;">
                  {{ val }}
                </button> 
              </ng-container>
            </div>
            <div *ngIf="!!attribute.clearable" style="text-align: right; position: relative; height: 30px; top: 30px; right: 6px; z-index: 99;">
              <ion-icon 
                (click)="attribute.value = ''; attribute.changed = true;" 
                name="close-circle-outline">
              </ion-icon>
            </div>
            <ion-textarea rows="5"
              style="margin-top: 0;"
              placeholder="{{attribute.placeholder}}" 
              [(ngModel)]="attribute.value" 
              (ionChange)="attribute.changed=true">
              </ion-textarea>
          </div>
          <!-- options -->
          <div *ngIf="['OPTIONS', 'RADIOCHIPS'].includes(attribute.inputType)">
            @if (attribute.inputSubtype === 'Chat') {
              <div 
              style="display: flex; flex-direction:column; align-items: center; gap: 10px; margin: 50px 0;">
              @for (option of attribute.optionLinks; track option.name) {
                  <ion-button
                    *ngIf="!option.disabled"                    
                    expand="block"
                    color="tertiary" 
                    style="width: 300px;"
                    (click)="conversationService.chatOptionClicked(option); $event.stopPropagation()">
                    <span 
                      class="option-text"
                      style="font-weight: bold;"
                      [innerHTML]="option.formattedDescription">
                    </span>
                  </ion-button>
              }
              </div>
            }
            @else {
            <ion-list lines="none">
              <ion-radio-group 
                [(ngModel)]="attribute.value" 
                (click)="radioGroupChanged($event); $event.stopPropagation()">
                  <ng-container *ngFor="let option of attribute.optionLinks; let i = index;">
                    <ion-item *ngIf="!option.disabled">                  
                      <ion-radio 
                        labelPlacement="end" 
                        justify="start"
                        mode="md"
                        [class]="((i % 2) && attribute.optionLinks.length > 3) ? 'even-item' : 'odd-item'" 
                        value="{{option.name}}">
                        <span class="option-text" [innerHTML]="option.formattedDescription"></span>
                      </ion-radio>
                    </ion-item>
                  </ng-container>
                  <ion-item *ngIf="attribute.inputType === 'COMBOBOX' || attribute.inputSubtype === 'IncludeOther'">                
                    <ion-radio 
                      labelPlacement="end" 
                      justify="start"
                      mode="md" 
                      class="option-text"
                      value="Other">
                        {{ attribute.otherValue || "Add Other..." }}
                    </ion-radio>
                  </ion-item>
              </ion-radio-group>
            </ion-list>
          }
          </div>
          <div *ngIf="attribute.inputType === 'COMBOBOX'">
            <ng-container *ngIf="attribute.choicelist.length > 0; else noOptions">
              <ion-list lines="none">
                <ion-radio-group 
                  [(ngModel)]="attribute.value" 
                  (click)="radioGroupChanged($event); $event.stopPropagation()">
                  <ng-container *ngFor="let option of attribute.choicelist; let i = index;">
                    <ion-item>
                      <ion-radio 
                        labelPlacement="end" 
                        justify="start"
                        mode="md"
                        [class]="((i % 2) && attribute.choicelist.length > 3) ? 'even-item' : 'odd-item'"  
                        value="{{option}}">
                        <span class="option-text" [innerHTML]="option"></span>
                      </ion-radio>
                    </ion-item>
                  </ng-container>
                  <ion-item>
                    <ion-radio 
                      labelPlacement="end" 
                      justify="start"
                      mode="md" 
                      class="option-text"
                      value="Other">
                        Other...
                    </ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
            </ng-container>
            <ng-template #noOptions>
              <div style="margin: 20px">
                <ion-input 
                  placeholder="{{attribute.placeholder}}" 
                  [(ngModel)]="attribute.value" 
                  (ionChange)="attribute.changed=true">
                </ion-input>                
              </div>
            </ng-template>
          </div>
          <!-- High/Medium/Low options -->
          <div *ngIf="attribute.inputType === 'SCALE'">
            <ion-grid>
              <ion-row>
                <ion-col size="12" class="scale-label">
                    <ion-segment [(ngModel)]="attribute.value" (ionChange)="scaleChanged($event)">
                        <ion-segment-button 
                          *ngFor="let i of attribute.scaleRange" 
                          value="{{i}}" 
                          class="scale-segment-button">
                          <ion-label>{{ attribute.scaleLabels[i - 1] }}</ion-label>
                        </ion-segment-button>
                      </ion-segment>
                  </ion-col>
              </ion-row>
            </ion-grid>    
          </div>
          <div *ngIf="attribute.inputType === 'RANKER'">
            <ion-list lines="none">
              <!-- The reorder gesture is disabled by default, enable it to drag and drop items -->
              <!-- Casting $event to $any is a temporary fix for this bug https://github.com/ionic-team/ionic-framework/issues/24245 -->
              <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event), attribute)">
                <ion-item *ngFor="let option of attribute.optionLinks; let i = index">
                  <!-- <div>{{ i }}</div> -->
                  <ion-reorder slot="start" style="display: flex; flex-direction: column; align-items: center">
                    <ion-icon size="large" name="reorder-three-outline"></ion-icon>
                    <ion-badge color="secondary">{{ i + 1 }}</ion-badge>
                  </ion-reorder>
                  <div [innerHtml]="option.formattedDescription"></div>
                </ion-item>
              </ion-reorder-group>
            </ion-list>
          </div>
          <!-- multi options -->
          <div *ngIf="attribute.inputType === 'MULTIOPTIONS'">
            <ion-list lines="none">
              <ng-container *ngFor="let option of attribute.optionLinks; let i = index;">
                <ion-item>
                  <ion-checkbox 
                    [name]="option.name" 
                    justify="start"
                    labelPlacement="end"
                    [class]="(i % 2) && option.formattedDescription.length >= 30 ? 'even-item' : 'odd-item'"
                    [(ngModel)]="option.isChecked" 
                    (ionChange)="conversationService.multiOptionSelected(option, attribute)" 
                    [disabled]="option.disabled">
                    <span class="{{ 'option-text' + (option.disabled ? ' disabled' : '') }}" [innerHTML]="option.formattedDescription"></span>
                  </ion-checkbox>
                </ion-item>
              </ng-container>
              <ion-item *ngIf="attribute.inputSubtype === 'IncludeOther'">
                <ion-checkbox 
                  name="other" 
                  justify="start"
                  labelPlacement="end"
                  [(ngModel)]="attribute.otherChecked"
                  class="option-text" 
                  (click)="clickOther($event); $event.stopPropagation()">
                    {{ attribute.otherValue || "Add Other..." }}
                </ion-checkbox>              
              </ion-item>
            </ion-list>
          </div>
          <!-- date -->
          <div *ngIf="attribute.inputType==='HOUROFDAY'">
            <ion-item detail>
              <ion-datetime 
                presentation="time"
                minuteValues="0"
                [(ngModel)]="attribute.value" 
                placeholder="Select a time" 
                (ionChange)="attribute.changed=true"></ion-datetime>
            </ion-item>
          </div>
          <div *ngIf="attribute.inputType==='SPINNER'" style="text-align:center; vertical-align: middle; height: 100px; padding: 20px;">
            <div style="display:inline-block">
              <cheaseed-counter-input 
                  [(ngModel)]="attribute.value" 
                  [min]="attribute.scaleMin || 0" 
                  [max]="attribute.scaleMax || 30"
                  (counterChanged)="attribute.changed=true">
              </cheaseed-counter-input>
            </div>
          </div>
          <!-- date -->
          <div *ngIf="attribute.inputType==='DATE'" style="text-align: center;">
            <mat-form-field>
              <input matInput
                [(ngModel)]="attribute.value"
                [min]="attribute.minDate"
                [matDatepicker]="picker" 
                (dateChange)="attribute.changed=true">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-label></mat-label>
            </mat-form-field>  
          </div>
          <div *ngIf="attribute.inputType==='SLIDER'" style="text-align: center; margin: 40px 20px;">
            <mat-slider
              style="width: 240px"
              [max]="attribute.sliderParams?.max"
              [min]="attribute.sliderParams?.min"
              [step]="attribute.sliderParams?.step"
              discrete="true"
              [displayWith]="sliderFormat.bind(this, attribute.sliderParams)"
              showTickMarks="true">
                <input matSliderThumb
                (valueChange)="attribute.changed=true" 
                [(ngModel)]="attribute.value">
            </mat-slider>
            <div>{{ sliderFormat(attribute.sliderParams, attribute.value) }}</div>
          </div>
        </div>

        <!-- Text Block -->
        <!-- only display if there's something to render -->
        <div *ngIf="currstmt.textBlock && !!currstmt.renderBlock && currstmt.questionFirst">
          <ng-container *ngIf="conversationService.currentTextRendering$ | async as renderBlock">
            <div class="statement-text" [style.background-color]="currstmt.customTextBackgroundColor" [innerHTML]="renderBlock"></div>
          </ng-container>
        </div>
        
        <!-- Video Player -->
        <ng-container *ngIf="conversationService.videoURL && !currstmt.isVideoFirst">
          <div style="padding:75% 0 0 0;position:relative;">
            <iframe [src]="conversationService.videoURL" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </ng-container>

        <div *ngIf="currstmt.addableEntrySpec as spec" class="add-button">
          <ng-container *ngIf="entryService.addableEntryMap$ | async as addeds">
            <ng-container *ngIf="!addeds.get(currstmt.name); else saved">
              <ion-button           
                fill="solid"
                (click)="addEntrySpec(spec.name); $event.stopPropagation()">
                {{ "+ " + contentService.getSingularGlobal(spec.name) }}
              </ion-button>
            </ng-container> 
            <ng-template #saved>
              <div style="text-align: center; font-size: 16px; font-weight: bold; color: var(--chea-purple); padding: 10px;">
                {{ contentService.getGlobal("chat.entryAdded.message")?.replace("$type", contentService.getSingularGlobal(spec.name)) }}
              </div>            
              <ion-button 
                fill="solid"
                (click)="continueForward(); $event.stopPropagation()">Next</ion-button>
            </ng-template>
          </ng-container>
        </div>

        <div *ngIf="(conversationService.currentAttribute() && currstmt.showNextButton) || (!conversationService.currentAttribute() && conversationService.videoURL) || currstmt.submitRequired" class="continue-button">
            <ion-button 
                fill="solid" 
                size="default"
                (click)="continueForward(); $event.stopPropagation()">
                {{ conversationService.videoURL ? 'Done' : (currstmt.submitRequired ? 'Submit' : 'Next') }}
            </ion-button>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #checkCompletionText>
    <div id="completionNode" *ngIf="completionTextBlock && conversationService.conversation.completed" class="statement-div">
      <div>
        <div *ngIf="conversationService.currerror$ | async as currerror" class="stmterror">
          <p> 
            {{ currerror.error + " in: " }}
            <b>{{ currerror?.source }}</b>
          </p>
        </div>
        <div class="statement-text" [innerHTML]="completionTextBlock"></div>
      </div>
      <div class="continue-button" *ngIf="conversationService.conversation as conversation">
        <ion-button 
          fill="solid"
          (click)="conversationService.setNextConversation(conversation.name); $event.stopPropagation()">
            Restart
        </ion-button>
        <ion-button 
          *ngIf="!isProduction()"
          fill="solid"
          (click)="auth.convertFromAnonymous(); $event.stopPropagation()">
            Sign Up
        </ion-button>
      </div>
    </div>
  </ng-template>
</div>

<ion-modal 
  [isOpen]="termModalState.open" 
  style="--height: 40%; --width: 65%;"
  [canDismiss]="true"
  (didDismiss)="termModalState.open = false">
  <ng-template>
    <ion-content style="--background: white" (click)="termModalState.open = false">
      <div style="display: flex; justify-content: space-between; padding: 20px">
        <div style="font-size: 18px; font-weight: bold; padding-bottom: 10px;">{{ termModalState.term }}</div>
        <ion-icon name="close"></ion-icon>
      </div>
      <div style="padding: 0 20px;">
        <div style="font-size: 14px;" [innerHTML]="termModalState.content"></div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

